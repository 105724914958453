import '../css/activities.css'
import standarddeluxe from '../assets/standard-deluxe.jpeg'
import canoe from '../assets/canoe.jpeg'
import jeepsafari from '../assets/jeepsafari.jpeg'
import junglewalk from '../assets/junglewalk.jpeg'
import washelephant from '../assets/elephant.jpeg'
import tharu from '../assets/tharu.jpeg'
import tharuvillage from '../assets/tharuvillage.jpeg'
export default function Activities() {
    return (
        
        
        <div className="activitiesContainer">
             <h2>Activities</h2>
             <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>
             <div className='activities_cards'>
                        <div className='activity_card'  >
                            <div className='actvity_card_image'>
                                <img src = {junglewalk} loading = 'lazy' alt = 'room image'/>
                            </div>
                           
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >Jungle Walk</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }} >A guided hike through the lush forests of Chitwan, offering close encounters with the park's wildlife, such as deer, birds, and potentially rhinos.</p>

                            <button className='read_more_btn' >Read More</button>

                            </div>
                        </div>
                        <div className='activity_card'  >
                            <div className='actvity_card_image'>
                                <img src = {jeepsafari} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >Jeep Safari</p>
                            <p  style={{fontSize : "14px",color:"gray", marginBottom : "5px" }}>A thrilling jeep or elephant ride through Chitwan's forests and grasslands, offering chances to spot rhinos, tigers, and elephants in the wild.</p>

                            <button className='read_more_btn' >Read More</button>
                            </div>
                        </div>
                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {canoe} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >Canoe Trip</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }} >A peaceful boat ride on the Rapti or Narayani rivers, offering views of aquatic birds, crocodiles, and wildlife along <br/>the banks.</p>
                            <button className='read_more_btn' >Read More</button>

                            </div>

                        </div>

                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {washelephant} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >Wash The Elephant</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }} >A fun experience where you bathe and scrub elephants in the river, enjoying playful moments with these gentle giants.</p>
                            <button className='read_more_btn' >Read More</button>
                            </div>
                           
                        </div>

                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {tharu} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >Tharu Cultural Show</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }}>A lively performance of traditional Tharu dances, music, and rituals, celebrating the rich heritage of the indigenous community.</p>
                            <button className='read_more_btn' >Read More</button>
                            </div>
                           
                        </div>
                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {tharuvillage} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >Tharu Village Tour</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }}>A guided tour to explore traditional Tharu homes and learn about their customs and lifestyle.</p>

                            <button className='read_more_btn' >Read More</button>
                            </div>
                           
                        </div>


                    </div>
        </div>
        
        
    )
}