import '../../css/rooms.css';
import str2 from '../../assets/str2.jpeg';
import str1 from '../../assets/str1.jpeg';
import bed from '../../assets/bed.png';
import car from '../../assets/car.png';
import otherAmenities from '../../assets/other-amenities.png';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from '../../components/modal';
import { usePackage } from '../../context/packageProvider';
import towerImg from '../../assets/tower.jpeg'
import towerImg2 from '../../assets/tower2.jpeg'
import tower3 from '../../assets/tower3.jpeg'
import tower4 from '../../assets/tower4.jpeg'
import tower5 from '../../assets/tower5.jpeg'
export default function DeluxeRoom(){

    const {t} = useTranslation();
    let [open,setOpen] = useState(false);
    let {changeRoom} = usePackage(); 
    function handleClick(){
        changeRoom('Deluxe Room')
        setOpen(true);
    }


    return(
        <div className='rooms'>
            <div className='rooms_image_container' >
                 <img loading='lazy' className='r1' src = {towerImg} alt = "room1"/>
                 <img loading='lazy' className='r2' src = {towerImg2} alt = "room1"/>
                 <img loading='lazy' className='r3' src = {tower3} alt = "room1"/>
                 <img loading='lazy' className='r4' src = {tower4} alt = "room1"/>
                 <img loading='lazy' className='r5' src = {tower5} alt = "room1"/>
            </div>
            <div className='room_description_container'>
             <div>
                <h2>{t('room.room2.title')}</h2>
                <div className='basic_facillities'>
                    {/* <div>
                        <img style={{'width' : '40px'}} src = {bed} alt = "bed"/>
                        <p>1 single bed & <br/>
                       1 double beds  </p>
                    </div> */}
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                    </svg>
                        <p>Maximum 1 <br/>people</p>
                    </div>

                    <div>
                    <img style={{'width' : '40px'}} src = {car} alt = "bed"/>
                        <p>Free pickup<br/> from bus stop</p>
                    </div>
                    {/* <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-wifi" viewBox="0 0 16 16">
                        <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                        <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                    </svg>
                        <p>Free <br/>wifi</p>
                    </div> */}
                  
                </div>
                <h2>{t('room.title2')}</h2>
                <br/>
                <span style = {{'color' : 'gray','letterSpacing' :'1px'}} >
                  {t('room.room2.desc')}
                </span>
                {/* <br/>
                <br/> */}
                {/* <h2>Offered Amenities</h2>
                <br/>
                <div className='amenities_container'>
                    <img style ={{'width' :'100%'}} src ={otherAmenities} alt = 'other-amenities' rel='something' loading='lazy' />

                </div> */}

             </div>
             <div className='price_container'>
                  <h4>60$ <span style={{fontSize:'16px'}} > per person</span></h4>
                  <div style={{'borderTop':'1px solid black'}} ></div>
                  <br/>
                  <p style={{'letterSpacing':'1px','color':'gray'}} >- Air conditioning <br/>- Free wifi</p>
                  <button onClick = {handleClick}  className='book_button' >Book now</button>
                  <div style={{'display':'flex','justifyContent':'center'}}>
      

                  <div className="horizontal-line"></div>
               <span>OR</span> <div className="horizontal-line"></div>
   

               </div>
               <p style={{'textAlign':'center'}} >Call : 061-123456</p>
             </div>
            </div>

            <Modal open={open} close = {()=>setOpen(false)} />

        </div>
    )
}